import { gql } from '@apollo/client';

export const GET_IS_OPEN_LIST_DICT = gql`
  query getIsOpenListDict {
    isOpenListDict @client
  }
`;

export const GET_LIST_DICTIONARY = gql`
  query getDictionary($languageId: ID) {
    getDictionary(languageId: $languageId) {
      id
      name
      tags
      language {
        id
        label
      }
    }
  }
`;
