import { Container, Modal, UncontrolledAlert, ModalBody } from 'reactstrap';
import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import Progress from '../Progress';
import { progressBarStore } from '../../context/ProgressBarContext';
import { GET_ID_SUBSCRIBE_PLAN, GET_PLAN, GET_USER_PROFILE, UPDATE_PLAN } from '../../queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';

enum PlanNameEnum {
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
  PRO = 'PRO',
  STARTER = 'STARTER',
}

enum BaseOptionsEnum {
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUAL',
}

enum StatusSubscriptionEnum {
  SUCCEED = 'SUCCEED',
  PENDING = 'PENDING',
}

const title = 'Pricing Pages';
const secret_key =
  'pk_test_51IqrioJRGmMexNcZgYmGAgpLiixRq7ANC0ordDHVXVIeZoGdPkjciuEBcBpbEH7voyJpj8xp3ePyQ2e6DE0pXKck00BIf1AAg8';

type ModalPriceProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
};

export default function ModalPrice({ toggle, isOpen }: ModalPriceProps) {
  const [dataPlanPro, setDataPlanPro] = useState<Plan>();
  const [dataPlanStarter, setPlanStarter] = useState<Plan>();
  const stripePromise = loadStripe(secret_key);
  const btnMonthly = useRef<HTMLButtonElement>(null);
  const btnAnnually = useRef<HTMLButtonElement>(null);
  const [listPlan, setListPlan] = useState<any>();
  const { pushProgress, completeProgress } = useContext(progressBarStore);
  const [currentPlanEnumUser, setCurrentPlanEnumUser] = useState<any>();
  const [currentPlanUpdate, setCurrentPlanUpdate] = useState<Plan>();
  const [errorMessage, setErrorMessage] = useState('');
  const [canUpdate, setCanUpdate] = useState(false);

  const [getPlanData, { loading: loadingPlan, data: planData }] = useLazyQuery<ListPlan>(GET_PLAN, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      completeProgress('loadingPlan');
      setListPlan(
        data.getPlan.map((plan: Plan) => {
          if (plan.active) {
            return plan;
          }
        })
      );
    },
    onError: e => {
      setErrorMessage(e.message);
    },
  });

  const [getUserData, { loading: loadingUserData, data: userData }] = useLazyQuery<{ getUserProfile: User }, never>(
    GET_USER_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: userData => {
        setCurrentPlanEnumUser(userData?.getUserProfile.planType?.plan_enum);
      },
    }
  );

  const [subscribePlan, { loading: loadingSubscribePlan, data: dataSubscribePlan }] = useMutation(
    GET_ID_SUBSCRIBE_PLAN,
    {
      onCompleted: subscribePlan => {
        completeProgress('loadingSubscribePlan');
        stripePromise
          .then(stripe => {
            stripe?.redirectToCheckout({
              sessionId: subscribePlan.subscribePlan,
            });
          })
          .catch(error => {
            setErrorMessage(error);
          });
      },
      onError: error => {
        setErrorMessage(error.message);
      },
    }
  );

  const [updatePlan, { loading: loadingUpdatePlan, data: dataUpdatePlan }] = useMutation(UPDATE_PLAN, {
    onCompleted: updatePlan => {
      completeProgress('loadingUpdatePlan');
    },
    onError: error => {
      setErrorMessage(error.message);
    },
  });

  useEffect(() => {
    if (isOpen) {
      getPlanData();
      getUserData();
    } else {
      setErrorMessage('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (loadingPlan) {
      pushProgress('loadingPlan');
    } else {
      if (userData?.getUserProfile?.planType?.plan_enum === PlanNameEnum.PAY_AS_YOU_GO) {
        btnMonthly.current?.classList.add('btn-active');
        loadDataItemPlan(BaseOptionsEnum.MONTHLY);
      } else {
        if (userData?.getUserProfile?.planType?.plan_enum !== undefined) {
          const option = userData?.getUserProfile.planType.plan_enum.substring(
            userData?.getUserProfile.planType?.plan_enum.indexOf('_') + 1
          );
          if (option === BaseOptionsEnum.ANNUALLY) {
            btnAnnually.current?.classList.add('btn-active');
          } else {
            btnMonthly.current?.classList.add('btn-active');
          }
          loadDataItemPlan(option);
          setCurrentPlanEnumUser(userData?.getUserProfile.planType.plan_enum);
        }
      }
    }
  }, [loadingPlan, loadingUserData]);

  useEffect(() => {
    if (loadingSubscribePlan) pushProgress('loadingSubscribePlan');
  }, [loadingSubscribePlan]);

  useEffect(() => {
    if (loadingUpdatePlan) {
      pushProgress('loadingUpdatePlan');
    } else {
      setCurrentPlanEnumUser(currentPlanUpdate?.plan_enum);
    }
  }, [loadingUpdatePlan]);

  useEffect(() => {
    const handleClickButton: EventListener = (event: Event): void => {
      setErrorMessage('');
      if (event.target === btnAnnually.current) {
        btnAnnually.current?.classList.add('btn-active');
        btnMonthly.current?.classList.remove('btn-active');
      }
      if (event.target === btnMonthly.current) {
        btnMonthly.current?.classList.add('btn-active');
        btnAnnually.current?.classList.remove('btn-active');
      }
    };
    document.addEventListener('mousedown', handleClickButton);
    return () => {
      document.removeEventListener('mousedown', handleClickButton);
    };
  }, [btnAnnually, btnMonthly]);

  useEffect(() => {
    if (isOpen) {
      const intervalGetUserData = setInterval(() => {
        if (
          userData?.getUserProfile.subscription_status === null ||
          userData?.getUserProfile.subscription_status === StatusSubscriptionEnum.SUCCEED
        ) {
          setCanUpdate(true);
        } else {
          setCanUpdate(false);
        }
      }, 2000);
      return () => {
        clearInterval(intervalGetUserData);
      };
    }
  }, [isOpen]);

  function loadDataItemPlan(option: string) {
    listPlan?.map((plan: any) => {
      if (plan === undefined) return;
      if (plan.plan_enum.includes(option.toUpperCase())) {
        if (plan.name.toUpperCase() === PlanNameEnum.PRO) {
          setDataPlanPro({
            ...plan,
          });
        }
        if (plan.name.toUpperCase() === PlanNameEnum.STARTER) {
          setPlanStarter({
            ...plan,
          });
        }
      }
    });
  }

  function handleSubscribe(data?: Plan) {
    if (data === undefined) return;
    if (canUpdate) {
      subscribePlan({
        variables: {
          planId: data.id,
        },
      });
    } else {
      setErrorMessage(`Your status: ${userData?.getUserProfile.subscription_status} ,please try again!`);
    }
  }

  function handleUpdate(data?: Plan) {
    if (data === undefined) return;
    if (canUpdate) {
      updatePlan({ variables: { planId: data.id } });
      setCurrentPlanUpdate(data);
    } else {
      setErrorMessage(`Your status: ${userData?.getUserProfile.subscription_status} ,please try again!`);
    }
  }

  return (
    <Modal id="modal-price" isOpen={isOpen} fade={false} toggle={() => toggle(!isOpen)}>
      <Progress />
      {errorMessage.length > 0 && <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>}
      <div>
        <h5>Choose your plan</h5>
        <button className="btn-close" onClick={() => toggle(!isOpen)}>
          <i className="fa fa-times"></i>
        </button>
        <p style={{ marginTop: '20px' }}>You can upgrade or downgrade at any time.</p>
        <div style={{ marginTop: '40px', marginBottom: '15px' }}>
          <button className="btn-option" onClick={() => loadDataItemPlan(BaseOptionsEnum.MONTHLY)} ref={btnMonthly}>
            Monthly
          </button>
          <button className="btn-option" onClick={() => loadDataItemPlan(BaseOptionsEnum.ANNUALLY)} ref={btnAnnually}>
            Annually
          </button>
        </div>
        <a href="">Save 20% with an annual plan</a>
        <div className="container-item-pricing">
          {/* STARTER */}
          <div className="item-pricing">
            <div style={{ display: 'flex', marginBottom: '21px' }}>
              <h3>🎙 {dataPlanStarter ? dataPlanStarter.label.toUpperCase() : PlanNameEnum.STARTER} </h3>
              {!loadingPlan && currentPlanEnumUser === dataPlanStarter?.plan_enum && (
                <span className="current-plan">Your Current Plan</span>
              )}
            </div>
            {dataPlanStarter?.plan_enum.includes(BaseOptionsEnum.MONTHLY) ? (
              <h4>
                ${!loadingPlan && dataPlanStarter ? dataPlanStarter.subscription_charge : '240'} <span>/ month</span>
              </h4>
            ) : (
              <h4>
                ${!loadingPlan && dataPlanStarter ? dataPlanStarter.subscription_charge / 12 : '240'}{' '}
                <span>/ month</span>
              </h4>
            )}

            <p>For freelancers</p>
            <ul>
              <li>
                <i className="fa fa-check-circle"></i> Incl. 24 hours of credit /year
              </li>
              <li>
                <i className="fa fa-check-circle"></i> The benefits of PAYG plus:
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Reduced cost to transcribe
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Collaborate with your team
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Suitable for larger projects
              </li>
            </ul>

            {currentPlanEnumUser === PlanNameEnum.PAY_AS_YOU_GO ? (
              <div className="container-button-plan">
                <button className="choose-plan btn-starter" onClick={() => handleSubscribe(dataPlanStarter)}>
                  Choose Plan
                </button>
              </div>
            ) : (
              <div className="container-button-plan">
                {currentPlanEnumUser === dataPlanPro?.plan_enum ? (
                  <button className="choose-plan btn-downgrade" onClick={() => handleUpdate(dataPlanStarter)}>
                    Downgrade
                  </button>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>

          {/* PRO */}
          <div className="item-pricing">
            <div style={{ display: 'flex', marginBottom: '21px' }}>
              <h3>🎙 {dataPlanPro ? dataPlanPro.label.toUpperCase() : PlanNameEnum.PRO} </h3>
              {!loadingPlan && currentPlanEnumUser === dataPlanPro?.plan_enum && (
                <span className="current-plan">Your Current Plan</span>
              )}
            </div>
            {dataPlanPro?.plan_enum.includes(BaseOptionsEnum.MONTHLY) ? (
              <h4>
                ${!loadingPlan && dataPlanPro ? dataPlanPro.subscription_charge : '240'} <span>/ month</span>
              </h4>
            ) : (
              <h4>
                ${!loadingPlan && dataPlanPro ? dataPlanPro.subscription_charge / 12 : '240'} <span>/ month</span>
              </h4>
            )}
            <p>For production pros</p>
            <ul>
              <li>
                <i className="fa fa-check-circle"></i> Incl. 48 hours of credit /year
              </li>
              <li>
                <i className="fa fa-check-circle"></i> The benefits of Starter plus:
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Enchaned Video Assembly Editor: edit video with your team
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Custom dictionaries for increased accuracy
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Import files up to 20GB
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Get access to the lastest AI speech models and features
              </li>
            </ul>

            {currentPlanEnumUser === PlanNameEnum.PAY_AS_YOU_GO ? (
              <div className="container-button-plan">
                <button className="choose-plan btn-pro" onClick={() => handleSubscribe(dataPlanPro)}>
                  Choose Plan
                </button>
              </div>
            ) : (
              <div className="container-button-plan">
                {currentPlanEnumUser === dataPlanStarter?.plan_enum ? (
                  <button className="choose-plan btn-pro" onClick={() => handleUpdate(dataPlanPro)}>
                    Upgrade
                  </button>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
