import React from 'react';

function IconDB() {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19.4018" cy="19.4018" r="19.4018" fill="#0062FF" />
      <path d="M13.7278 10.25L8.05371 13.89L13.7278 17.4765L19.4019 13.89L13.7278 10.25ZM25.076 10.25L19.4019 13.89L25.076 17.4765L30.7502 13.89L25.076 10.25ZM8.05371 21.1165L13.7278 24.7564L19.4019 21.1165L13.7278 17.4765L8.05371 21.1165ZM25.076 17.4765L19.4019 21.1165L25.076 24.7564L30.7502 21.1165L25.076 17.4765ZM13.7278 25.9341L19.4019 29.5741L25.076 25.9341L19.4019 22.3476L13.7278 25.9341Z" 
          fill="white" />
    </svg>

  );
}

export default IconDB;
