import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useState, useCallback } from 'react';
import { Modal, Button } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { UPDATE_AVATAR_PROFILE, GET_USER_PROFILE, GET_SIGNED_URL_AVATAR } from '../../queries';
import { useEffect } from 'react';

type ModalProfileProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  // userProfile: User;
};

function sizeFileValidator(file: any) {
  if (file.size > 1024 * 300) {
    return {
      code: 'file-too-large',
      message: `File is larger than 300kb`,
    };
  }

  return null;
}

function DropZoneUploadFile({ setFileUpload, setIsHaveFile }: any) {
  const [filePreview, setFilePreview] = useState<any>(null);
  const onDrop = useCallback(acceptedFiles => {
    console.log('acceptedFiles', acceptedFiles);
    if (acceptedFiles.length > 0) {
      setFilePreview(URL.createObjectURL(acceptedFiles[0]));
      setFileUpload(acceptedFiles[0]);
      setIsHaveFile(true);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    validator: sizeFileValidator,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }, idx) => (
    <div key={idx}>
      {errors.map(e => (
        <div className="message-reject-file" key={e.code}>
          {e.message}
        </div>
      ))}
    </div>
  ));

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="drag-profile">
        <img className="icon-drag" src={filePreview ? filePreview : '/img/icon-drag.png'} />
        <div className="box-txt-drag">
          <div className="txt-drag">Drag and drop your image here.</div>
          <div className="sub-txt-drag">Square image less than 300Kb</div>
        </div>
      </div>
      {/* {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>} */}
      {fileRejectionItems}
    </div>
  );
}

const ModalUpdateProfile = ({ isOpen, toggle }: ModalProfileProps) => {
  const listProfileData = [
    { id: 1, imgUrl: '/img/avatar1.png' },
    { id: 2, imgUrl: '/img/avatar1.png' },
    { id: 3, imgUrl: '/img/avatar1.png' },
    { id: 4, imgUrl: '/img/avatar1.png' },
    { id: 5, imgUrl: '/img/avatar1.png' },
  ];
  const [currentChooseProfile, setCurrentChooseProfile] = useState<any>(listProfileData[0]);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [isHaveFile, setIsHaveFile] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [getSignedUrlAvatar, _] = useMutation(GET_SIGNED_URL_AVATAR);
  const [updateAvatarProfile, __] = useMutation(UPDATE_AVATAR_PROFILE);
  const [isProcessing, setIsProcessing] = useState(false);
  const isChoose = (idx: number) => currentChooseProfile.id !== idx;
  const classOpacity = 'rss opacity';
  const { data, refetch } = useQuery<{ getUserProfile: User }, never>(GET_USER_PROFILE, {});
  const submitUpdateProfile = async () => {
    setIsProcessing(true);
    if (currentChooseProfile.id === 6) {
      if (isHaveFile === false) {
        setValidationErrors(['Please drag and drop your image!']);
        return;
      } else {
        try {
          const resUpdateBE = await getSignedUrlAvatar({
            variables: { filename: fileUpload.name, mimeType: fileUpload.type },
          });
          await fetch(resUpdateBE.data.getAvatarSignedUploadUrl.url, {
            method: 'PUT',
            body: fileUpload,
            headers: {
              'Content-Type': fileUpload.type,
            },
          });
          const urlAvatar = resUpdateBE.data.getAvatarSignedUploadUrl.url.substring(
            0,
            resUpdateBE.data.getAvatarSignedUploadUrl.url.indexOf('?AWS')
          );
          await updateAvatarProfile({ variables: { avatar: urlAvatar } });
          await refetch();
          toggle(false);
        } catch (err) {
          throw new Error(err);
        }
      }
    } else {
      await updateAvatarProfile({ variables: { avatar: currentChooseProfile.imgUrl } });
      toggle(false);
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    if (isHaveFile) {
      setIsHaveFile(false);
      setValidationErrors([]);
    }
  }, [currentChooseProfile]);
  return (
    <Modal id="modal-update-profile" isOpen={isOpen} fade={false} toggle={() => toggle(!isOpen)} className="">
      <div className="d-flex justify-content-between">
        <div className="center">
          <span className="txt-title">Update Profile Pic</span>
        </div>
        <span style={{ cursor: 'pointer', color: '#828282' }} onClick={() => toggle(!isOpen)}>
          <FontAwesomeIcon icon={faTimes} width={16} height={16} />
        </span>
      </div>
      <div className="modal-content">
        <div className="ctn-list-profile">
          <div className="list-profile">
            {listProfileData.map(item => (
              <img
                key={item.id}
                onClick={() => setCurrentChooseProfile(item)}
                className={`profile-img ${isChoose(item.id) ? classOpacity : ''}`}
                src={item.imgUrl}
              />
            ))}
            <div className="profile-img current-profile-pic">
              <img
                onClick={() => setCurrentChooseProfile({ id: 6 })}
                className={`new-avatar ${isChoose(6) ? classOpacity : ''}`}
                src="/img/avatar-none.png"
              />
            </div>
          </div>
        </div>
        {currentChooseProfile.id === 6 ? (
          <div className="ctn-drag-profile">
            <DropZoneUploadFile setFileUpload={setFileUpload} setIsHaveFile={setIsHaveFile} />
            {validationErrors.map((item, idx) => (
              <div key={idx} className="message-reject-file">
                {item}
              </div>
            ))}
          </div>
        ) : null}

        <div className="ctn-button">
          <Button className="btn-update-profile" onClick={() => submitUpdateProfile()}>
            {isProcessing ? (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />
            ) : (
              <div>
                <span>Update</span>
              </div>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUpdateProfile;
