import React, { useState, useRef } from 'react';
import { Progress } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import { GET_SIGNED_URL, IMPORT_FILE_TO_PROJECT, CREATE_MEDIA, DELETE_FILE_UPLOAD } from '../../queries';
import GooglePicker from "../upload-video/GooglePicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import IconLocal from "../svg/IconLocal";
import IconDB from "../svg/IconDB";
import IconGD from "../svg/IconGD";
import IconInDD from "../svg/IconInDD";

declare const Dropbox: Dropbox;
interface Dropbox {
  choose(options: DropboxChooseOptions): void;
}
interface DropboxChooseOptions {
  success(files: DropboxFile[]): void;
  cancel?(): void;
  linkType: "direct" | "preview";
  multiselect: boolean;
  extensions?: string[];
}
interface DropboxFile {
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string;
  isDir: boolean;
}

type UploadVideoProp = {
  handleLoadFiles: (listFile: FileCloud[]) => void;
  onAuthenticateGD?: (token: string) => void;
}

enum InputFileEnum {
  LOCAL = "LOCAL",
  DROPBOX = "DROPBOX",
  DRIVE = "DRIVE",
}

const UploadVideo = ({ handleLoadFiles, onAuthenticateGD }: UploadVideoProp) => {

  // const [accessTokenGD, setAccessTokenGD] = useState('')
  // const router = useRouter();
  // const [tokenBox, setTokenBox] = useState(null)
  const inputFileRef = useRef<any>(null);

  const { getRootProps: getRootPropsPublish, getInputProps: getInputPropsPublish, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      onClickLocal(acceptedFiles);
    },
    accept: '.mp4,.mov',
    noClick: true
  });

  // useEffect(() => {
  //   console.log("boxCode", router?.query?.code)
  //   if (router?.query?.code) {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ grant_type: 'authorization_code', code: router.query.code, client_id: '5ip7qmuqnmy26crnqawvlkccaf2id4yd', client_secret: 'JqAvim3Pov0rzziPLwOllIsINuupxpfY' })
  //     };
  //     fetch('https://api.box.com/oauth2/token', requestOptions)
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log('access_token', data.access_token)
  //         if (data.access_token) {
  //           setTokenBox(data.access_token)
  //         }
  //       });
  //   }
  // }, [router?.query?.code])

  async function onClickLocal(acceptedFiles: any) {
    const tempAcceptedFiles: FileCloud[] = []
    for (const file of acceptedFiles) {
      const duration = await getDuration(file);
      tempAcceptedFiles.push({ type: InputFileEnum.LOCAL, file: file, fileName: file.name, durationInSeconds: duration as number, fileSize: file.size })
    }
    handleLoadFiles(tempAcceptedFiles);
    inputFileRef.current.value = null;
  }

  function getDuration(file: any) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(Math.round(video.duration));
      };

      video.onerror = function (err) {
        reject(err);
      };
      video.src = URL.createObjectURL(file);
    });
  }

  function onClickDopbox() {
    const options: DropboxChooseOptions = {
      success: (files) => {
        // console.log("dropbox", files);
        const listTempDB: FileCloud[] = [];
        for (const file of files) {
          listTempDB.push({
            type: InputFileEnum.DROPBOX, fileName: file.name,
            fileSize: file.bytes, linkToFile: file.link
          })
        }
        handleLoadFiles(listTempDB);
      },
      cancel: () => { },
      linkType: "direct",
      multiselect: true,
      extensions: ['.mp4'],
    };
    Dropbox.choose(options);
  }

  function onClickGoogle(data: any) {
    // console.log('on change:', data)
    if (data.action !== "picked") return;
    const listTempGD: FileCloud[] = [];
    for (const file of data.docs) {
      listTempGD.push({
        type: InputFileEnum.DRIVE, fileName: file.name,
        fileSize: file.sizeBytes, linkToFile: file.id,
      })
    }
    handleLoadFiles(listTempGD);
  }

  return (
    <div className="p-0 mr-5 mt-3">
      <div {...getRootPropsPublish()}>
        <div className="d-flex align-items-center justify-content-center con-drag-drop"
          style={{ background: isDragActive ? "rgba(255, 255, 255, 0.1)" : "" }}
        >
          <IconInDD />
          <div className="ml-2">
            <div className="txt-line-up">Drag and drop your audio & video files here</div>
            <div className="txt-line-down">File Supported: Mp4, Mov, Mp3. Maximum size: 100Gb</div>
          </div>
        </div>
        <input {...getInputPropsPublish()} />
      </div>
      <div className="d-flex flex-row mt-2">
        <a className="upload-icon dropbox-upload mr-2" onClick={() => document.getElementById("inp")?.click()}>
          <IconLocal />
          <input ref={inputFileRef} multiple accept=".mp4,.mov" id="inp" type='file' style={{ display: "none" }}
            onChange={(e) => onClickLocal(e.target.files)} />
        </a>
        <a className="upload-icon dropbox-upload mr-2" onClick={onClickDopbox}>
          <IconDB />
        </a>
        <GooglePicker
          onChange={onClickGoogle}
          onAuthenticate={onAuthenticateGD}
          onAuthFailed={(data: any) => console.log('on auth failed:', data)}
          multiselect={true}
          navHidden={false}
          authImmediate={false}
        >
          <a className="upload-icon dropbox-upload mr-2">
            <IconGD />
          </a>
        </GooglePicker>
        {/* <a className="upload-icon dropbox-upload mr-2" onClick={() => {
          const baseUrl = "https://account.box.com/api/oauth2/authorize";
          const clientId = "5ip7qmuqnmy26crnqawvlkccaf2id4yd";
          const authorizationUrl = `${baseUrl}?client_id=${clientId}&response_type=code`;
          window.open(authorizationUrl, '_self')
        }}>
          <img className="icon" alt="" src="img/btn-box.png" />
        </a> */}
      </div>
      <div style={{ background: 'rgba(255, 255, 255, 0.5)', width: "100%", height: 1, margin: "10px 0" }} />
      {/* {
        Object.keys(fileUpload).map((mediaKey, index) => (
          <div key={index} className="d-flex flex-row con-progress-upload">
            <div className="txt-filename">{mediaKey}</div>
            <div style={{ flex: 1 }}>
              <div style={{ fontSize: 10, textAlign: 'right' }}>
                {`${Math.round(fileUpload[mediaKey].percentage)}% Uploading`}
              </div>
              <Progress className="progress-upload" value={Math.round(fileUpload[mediaKey].percentage)} />
            </div>
            <div className="d-flex align-items-center justify-content-center con-icon-delete-upload">
              <FontAwesomeIcon icon={faTimes} width={14} height={14} style={{ cursor: 'pointer' }}
                onClick={() => onClickAbort(mediaKey)} />
            </div>
          </div>
        ))
      } */}
    </div>
  );
};

export default UploadVideo;
