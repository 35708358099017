import { useQuery } from '@apollo/client';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { CustomInput, Dropdown, DropdownMenu, DropdownToggle, Popover } from 'reactstrap';
import { GET_LANGUAGE_TRANSCRIBE } from '../../queries';
import DropdownCreateDict from '../Dropdown/DropdownCreateDict';
import { GET_IS_OPEN_LIST_DICT } from '../../reactivities/operations/queries/dictionary';
import { updateDictionaryMutations } from '../../reactivities/operations/mutations/dictionary';
import IconCreateDictionary from './../svg/IconCreateDictionary';

type ModalMediaLanguageProps = {
  onClose?: () => void;
  onClickOk: (language: LanguageRegion, media: ImportedMedia, dictionary?: DictionaryResponse | null) => void;
  media: ImportedMedia;
  languageRegions: LanguageRegion[];
  lastTranscibe: LanguageRegion;
  dictionarys: DictionaryResponse[];
};

const ModalMediaLanguage = ({
  onClose,
  onClickOk,
  dictionarys,
  media,
  languageRegions,
  lastTranscibe,
}: ModalMediaLanguageProps) => {
  const [isCheckLastest, setCheckLatest] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownDictionaryOpen, setDropdownDictionaryOpen] = useState(false);
  const [language, setLanguage] = useState('');
  const [dictionary, setDictionary] = useState<DictionaryResponse | null>();
  const [currentLanguage, setCurrentLanguage] = useState<LanguageRegion>({});
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isCheckDictionary, setCheckDictionary] = useState(false);
  const {data: listDictData } = useQuery(GET_IS_OPEN_LIST_DICT);
  const [hasLanguageDictionary, setHasLanguageDictionary] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const toggleDictionary = () => setDropdownDictionaryOpen(prevState => !prevState);

  useEffect(() => {
    document.addEventListener('keypress', function(event) {
      const isLetter = /^[a-z]$/i.test(event.key);
      if (isLetter) {
        const temp = languageRegions.find(
          languageRegion =>
            languageRegion.language?.label.charAt(0).toLocaleLowerCase() == event.key.toLocaleLowerCase()
        );
        if (!temp) return;
        document.getElementById(`${temp.language?.label}`)?.scrollIntoView();
      }
    });
  }, []);

  useEffect(() => {
    if (lastTranscibe) {
      setCheckLatest(true);
      setCurrentLanguage(lastTranscibe);
    }
  }, [lastTranscibe]);


  function openModalDictionary(){
    if (listDictData.isOpenListDict == false) {
      updateDictionaryMutations.updateIsOpenListDict(true);
      const element = document.getElementById('container-dictionary') as HTMLElement;
      if(element) element.click();
    } else {
      updateDictionaryMutations.updateIsOpenListDict(false);
    }
  }

  useEffect(() => {
    const res = dictionarys.find((value: any) => {
      const languageId = value.language.id;
      if (languageId === lastTranscibe.language?.id) return true;
      return false;
    });
    if(res === undefined) {setHasLanguageDictionary(false)}
    else{
      setHasLanguageDictionary(true)
    }
  }, [dictionarys, lastTranscibe]);

  return (
    <Popover
      isOpen={popoverOpen}
      onMouseDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      toggle={() => {
        if (popoverOpen && onClose) onClose();
        setPopoverOpen(prevState => !prevState);
      }}
      trigger="legacy"
      placement="bottom-start"
      target={`uncontrolled-popover-${media.id}`}
    >
      <div className="con-modal-transcribe">
        <div style={{ marginBottom: 10 }}>MEDIA LANGUAGE</div>
        {Object.keys(lastTranscibe).length > 0 && (
          <label className="radio radio-gradient">
            <span className="radio__input">
              <input
                type="radio"
                name="radio"
                checked={isCheckLastest}
                onChange={e => {
                  e.stopPropagation();
                  setCheckLatest(true);
                  setCurrentLanguage(lastTranscibe);
                  setDictionary(null);
                }}
              />
              <span className="radio__control"></span>
            </span>
            <span className="radio__label">{`${lastTranscibe.language?.label} ${lastTranscibe.name} (Last used)`}</span>
          </label>
        )}
        <label className="radio radio-gradient" style={{ marginTop: 5 }}>
          <span className="radio__input">
            <input
              type="radio"
              name="radio"
              checked={!isCheckLastest}
              onChange={e => {
                e.stopPropagation();
                setCheckLatest(false);
                setLanguage('');
                setDictionary(null);
              }}
            />
            <span className="radio__control"></span>
          </span>
          <span className="radio__label">Other language</span>
        </label>
        {!isCheckLastest && (
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 28, cursor: 'pointer' }}>
                <span style={{ width: 120 }}>{language ? language : 'Select language'}</span>
                <FontAwesomeIcon icon={faAngleDown} width={10} height={10} />
              </div>
            </DropdownToggle>
            <DropdownMenu style={{ height: 200, overflow: 'auto' }}>
              {languageRegions.map((languageRegion, index) => (
                <div
                  key={index}
                  id={`${languageRegion?.language?.label}`}
                  onClick={() => {
                    setLanguage(`${languageRegion?.language?.label} ${languageRegion.name}`);
                    toggle();
                    setCurrentLanguage(languageRegion);
                    setDictionary(null);
                  }}
                >{`${languageRegion?.language?.label} ${languageRegion.name}`}</div>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
        <div className="toggle-button-cover">
          <div className="button-cover">
            <div className="button r" id="button-1">
              <input
                type="checkbox"
                className="checkbox"
                checked={isCheckDictionary}
                onChange={() => setCheckDictionary(prev => !prev)}
              />
              <div className="knobs"></div>
              <div className="layer"></div>
            </div>
          </div>
          <div className="txt-custom">
            Custom Dictionary <DropdownCreateDict />
          </div>
        </div>
        {isCheckDictionary && 
          <Dropdown isOpen={dropdownDictionaryOpen} toggle={toggleDictionary}>
            {
              hasLanguageDictionary ? 
              <>
                <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownDictionaryOpen}>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 28, cursor: 'pointer' }}>
                    <span style={{ width: 120 }}>{dictionary ? dictionary.name : 'Select dictionary'}</span>
                    <FontAwesomeIcon icon={faAngleDown} width={10} height={10} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: 200, overflow: 'auto' }}>
                  {dictionarys
                    .filter(item =>
                      Object.keys(currentLanguage).length > 0 ? item.language.id === currentLanguage.language?.id : item
                    )
                    .map((dictionary, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          toggleDictionary();
                          setDictionary(dictionary);
                        }}
                      >
                        {dictionary.name}
                      </div>
                    ))}
                </DropdownMenu>
              </>
              : 
              <div className="create-dictionary" onClick={openModalDictionary}>Create your dictionary <IconCreateDictionary /></div>
             }
          </Dropdown>
        }
        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <span style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => setPopoverOpen(false)}>
            CANCEL
          </span>
          <span
            style={{ color: 'rgba(200, 36, 127, 1)', cursor: 'pointer' }}
            onClick={() => {
              setPopoverOpen(false);
              onClickOk(currentLanguage, media, dictionary);
              if (onClose) onClose();
            }}
          >
            OK
          </span>
        </div>
      </div>
    </Popover>
  );
};

export default ModalMediaLanguage;
