import React, { useState } from 'react';
import { Popover } from 'reactstrap';

type ModalInsufficientCreditProps = {
  onClose?: () => void;
  onClickOk: () => void;
  media: ImportedMedia;
}

const ModalInsufficientCredit = ({ onClose, onClickOk, media }: ModalInsufficientCreditProps) => {

  const [popoverOpen, setPopoverOpen] = useState(true);
  
  return (
    <Popover isOpen={popoverOpen}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      toggle={() => {
        if (popoverOpen && onClose) onClose();
        setPopoverOpen(prevState => !prevState);
      }}
      trigger="legacy" placement="bottom-start" target={`uncontrolled-popover-${media.id}`}>

      <div className="d-flex flex-row align-items-center justify-content-center"
        style={{
          width: 256, height: 65,
          borderRadius: 10,
          border: '1px solid rgba(143, 155, 179, 0.3)',
          background: '#1D253E',
          fontSize: 12, fontWeight: 500,
        }}
      >

        <div
          style={{
            color: '#C8247F',
            cursor: 'pointer',
            marginRight: 30
          }}
          onClick={() => {
            setPopoverOpen(false)
            onClickOk()
            if(onClose) onClose()
          }}
        >
          INSUFFICIENT CREDIT
      </div>

        <div style={{ color: "#8F9BB3", cursor: 'pointer', }}
          onClick={() => {
            setPopoverOpen(false)
            if(onClose) onClose()
          }}
        >CANCEL</div>

      </div >
    </Popover >
  );
};

export default ModalInsufficientCredit;
