import React from 'react';

const IconRetry = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8397 12.0312L9.16963 0.65625C9.00287 0.382812 8.75275 0.191406 8.44703 0.0820312C8.14132 -0.0273438 7.83561 -0.0273438 7.52989 0.0820312C7.22418 0.191406 7.00184 0.382812 6.83509 0.65625L0.164976 12.0312C-0.00177638 12.332 -0.0295685 12.6328 0.0260158 12.9336C0.0816 13.2344 0.220561 13.4805 0.47069 13.6992C0.693027 13.918 0.99874 14 1.33225 14H14.6725C15.006 14 15.2839 13.918 15.534 13.6992C15.7564 13.4805 15.9231 13.2344 15.9787 12.9336C16.0343 12.6328 15.9787 12.332 15.8397 12.0312ZM8.00236 9.67969C8.33586 9.67969 8.64158 9.81641 8.89171 10.0625C9.14184 10.3086 9.2808 10.6094 9.2808 10.9375C9.2808 11.293 9.14184 11.5938 8.89171 11.8398C8.64158 12.0859 8.33586 12.1953 8.00236 12.1953C7.64106 12.1953 7.33535 12.0859 7.08522 11.8398C6.83509 11.5938 6.72392 11.293 6.72392 10.9375C6.72392 10.6094 6.83509 10.3086 7.08522 10.0625C7.33535 9.81641 7.64106 9.67969 8.00236 9.67969ZM6.7795 5.16797C6.7795 5.05859 6.8073 4.97656 6.86288 4.92188C6.91846 4.86719 7.00184 4.8125 7.11301 4.8125H8.89171C8.97508 4.8125 9.05846 4.86719 9.11404 4.92188C9.16963 4.97656 9.22521 5.05859 9.22521 5.16797L9.00287 8.88672C9.00287 8.96875 8.94729 9.05078 8.89171 9.10547C8.83612 9.16016 8.75275 9.1875 8.66937 9.1875H7.33535C7.22418 9.1875 7.1408 9.16016 7.08522 9.10547C7.02963 9.05078 7.00184 8.96875 7.00184 8.88672L6.7795 5.16797Z" fill="#FF5630" />
    </svg>

  );
};

export default IconRetry;
