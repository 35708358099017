import React from 'react';

const IconCreateDictionary = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 1.375C10.5 0.765625 9.98438 0.25 9.375 0.25H1.125C0.492188 0.25 0 0.765625 0 1.375V9.625C0 10.2578 0.492188 10.75 1.125 10.75H9.375C9.98438 10.75 10.5 10.2578 10.5 9.625V1.375ZM8.4375 1.75C8.74219 1.75 9 2.00781 9 2.3125V4.9375C9 5.45312 8.39062 5.6875 8.03906 5.33594L7.28906 4.58594L2.69531 9.17969C2.57812 9.29688 2.41406 9.29688 2.29688 9.17969L1.57031 8.45312C1.45312 8.33594 1.45312 8.17188 1.57031 8.05469L6.16406 3.46094L5.41406 2.71094C5.03906 2.35938 5.29688 1.75 5.8125 1.75H8.4375Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCreateDictionary;
