import { faAngleDown, faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Input, Popover } from 'reactstrap';

type ModalTranslateProps = {
  onClose?: () => void;
  onClickOk: (media: ImportedMedia, listTranslate: Language[]) => void;
  media: ImportedMedia;
  languages: Language[];
}

const ModalTranslate = ({ onClose, onClickOk, media, languages }: ModalTranslateProps) => {

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [listLanguage, setListLanguage] = useState<Language[]>([]);
  const [inputLanguage, setInputLanguage] = useState('');
  const [focusInput, setFocusInput] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Popover isOpen={popoverOpen}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      toggle={() => {
        if (popoverOpen && onClose) onClose();
        setPopoverOpen(prevState => !prevState);
      }}
      trigger="legacy" placement="bottom-start" target={`uncontrolled-popover-${media.id}`}>

      <div className="con-modal-translate">
        <div className="txt-translate">TRANSLATE</div>

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            tag="div"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
          >
            <div className="d-flex align-items-center con-input-language"
              style={{ borderColor: focusInput ? '#C8247F' : 'rgba(143, 155, 179, 0.3)' }}
            >
              <Input id="input-language" placeholder="Select languages"
                onChange={(e) => {
                  setInputLanguage(e.target.value);
                  setDropdownOpen(true);
                }}
                onFocus={() => setFocusInput(true)}
                onBlur={() => setFocusInput(false)}
              />
              <FontAwesomeIcon icon={faAngleDown} width={10} height={10} />
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {
              languages
                .filter(language => language.label.toLocaleLowerCase().includes(inputLanguage.toLocaleLowerCase()))
                .map((language, index) => (
                  <div key={index} className="d-flex align-items-center" style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (listLanguage.some(item => item.id === language.id)) {
                        setListLanguage(prev => prev.filter(item => item.id !== language.id));
                      } else {
                        setListLanguage(prev => prev.concat(language));
                      }
                    }}
                  >
                    <div style={{ width: 18 }}>
                      {listLanguage.some(item => item.id === language.id) &&
                        < FontAwesomeIcon icon={faCheck} width={10} height={10} />}
                    </div>

                    <div >{language.label}</div>
                  </div>
                ))
            }
          </DropdownMenu>
        </Dropdown>

        <div className="d-flex con-selected-language">
          {
            listLanguage.map((language, idx) => (
              <div key={idx} className="item-selected-language d-flex align-items-center">
                <span className="txt-selected-langugae">{language.label}</span>
                <FontAwesomeIcon icon={faTimes} width={10} height={10} style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setListLanguage(prev => prev.filter(item => item.id !== language.id));
                  }}
                />
              </div>
            ))
          }
        </div>

        <div className="d-flex justify-content-end con-btn-bottom">
          <span className="btn-cancel"
            onClick={() => {
              setPopoverOpen(false);
              if(onClose) onClose()
            }}
          >CANCEL</span>
          <span className="btn-ok"
            onClick={() => {
              setPopoverOpen(false);
              onClickOk(media, listLanguage);
              // onClose();
            }}
          >OK</span>
        </div>
      </div>

    </Popover>
  );
};

export default ModalTranslate;
