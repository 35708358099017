import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch, faPlus, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Button, Collapse, Progress, Spinner } from 'reactstrap';
import { useTextInput, useTextInputSettable } from '../../common/custom-hook';
import { faTrashAlt, faFolder, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UploadVideo from '../upload-video/UploadVideo';
import IconFile from "../svg/IconFile";
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { CHARGE_CREDIT_MEDIA_BEFORE, CHARGE_EXTRA_CREDIT, GET_STATUS_TRANSCRIBE, PROCESS_UPLOAD_FILE, RETRIVED_TRANSCRIBE, SUB_TRANSLATE, TRANSCRIBE_MEDIA, TRANSLATE_MEDIA } from '../../queries';
import ModalMediaLanguage from '../Modal/ModalMediaLanguage';
import IconTranscribe from '../svg/IconTranscribe';
import IconRetry from '../svg/IconRetry';
import ModalTranslate from '../Modal/ModalTranslate';
import ModalInsufficientCredit from '../Modal/ModalInsufficientCredit';
import ModalPayment from '../Modal/ModalPayment';
import ModalAddCredit from '../Modal/ModalAddCredit';

type BinProps = {
  mediaFolders?: ProjectFolder[];
  loaded?: ImportedMedia[];
  projectId?: string;
  languages?: Language[];
  languageRegions?: LanguageRegion[];
  lastTranscibe?: LanguageRegion;
  userHasWritePermission?: boolean;
  loadedPanelActive?: boolean;
  dictionarys?: DictionaryResponse[];
  handleChangeSelected: (media: ImportedMedia) => void;
  openModalConfirmDeleteMedia?: (media: ImportedMedia) => void;
  handleCreateMediaFolder?: (folderName: string, callback: Function) => void;
  handleAmendMedia?: (mediaId: string, folderId: string | null) => void;
  handleAmendMediaFolder?: (folderId: string, folderName: string) => void;
  openModalConfirmDeleteFolder?: (folder: ProjectFolder) => void;
  handleLoadFiles: (listFile: FileCloud[]) => void;
  onAuthenticateGD?: (token: string) => void;
  onStartTranscribe?: (media: ImportedMedia, currentLanguage?: LanguageRegion, dictionaryId?: string) => void;
  onResultTranscribe?: (data: JobTranscribe) => void;
  abortUploadFile?: (media: ImportedMedia) => void;
  onSubUploadFile?: (data: PercentageProcessData) => void;
  onSubTranslateFile?: (data: ProgressTranslateResponse) => void;
  onStartTranslate?: (media: ImportedMedia, listTranslate: Language[]) => void;
  onTranscribeDone?: (media: ImportedMedia) => void;
  onChargeCreditBefore?: (media: ImportedMedia, languages: string[], type: string) => void;
  onOpenModalPayment?: (media: ImportedMedia) => void;
  onCloseModalInsufficientCredit?: (media: ImportedMedia) => void;
};

enum ItemTypes {
  MEDIA = "MEDIA"
}

enum TranscribeStatus {
  TRANSCRIBED = "transcribed",
  TRANSCRIBING = "transcribing",
  REJECTED = "rejected",
  FAILED = "failed",
  TRANSLATED = "translated",
  TRANSLATING = "translating",
  READY = "ready",
  TRANSCRIBE_PAID = "transcribe_paid",
  TRANSLATE_PAID = "translate_paid",
  UPLOADING = "uploading",
  TRANSCODING = "transcoding"
}

enum ExtraEnum {
  TOTALCREDITREQUIRED = 'TOTALCREDITREQUIRED',
  CUSTOM_10 = "CUSTOM_10",
  CUSTOM_20 = "CUSTOM_20",
  CUSTOM_30 = "CUSTOM_30",
}

export default function Bin({
  mediaFolders,
  loaded,
  projectId,
  languages,
  languageRegions,
  lastTranscibe,
  loadedPanelActive,
  userHasWritePermission,
  dictionarys,
  handleChangeSelected,
  openModalConfirmDeleteMedia,
  openModalConfirmDeleteFolder,
  handleCreateMediaFolder,
  handleAmendMedia,
  handleAmendMediaFolder,
  handleLoadFiles,
  onAuthenticateGD,
  onStartTranscribe,
  onResultTranscribe,
  abortUploadFile,
  onSubUploadFile,
  onSubTranslateFile,
  onStartTranslate,
  onTranscribeDone,
  onChargeCreditBefore,
  onOpenModalPayment,
  onCloseModalInsufficientCredit,
}: BinProps) {
  const search = useTextInput('');
  const inputCreateFolder = useTextInputSettable('');
  const [currentClickedMedia, setCurrentClickedMedia] = useState(null)

  function open(media: ImportedMedia) {
    if (openModalConfirmDeleteMedia) {
      openModalConfirmDeleteMedia(media)
    }
  }

  function createMeidaProject() {
    if (handleCreateMediaFolder && typeof handleCreateMediaFolder === "function") {
      handleCreateMediaFolder(inputCreateFolder.value, clearInputText);
    }
  }

  function clearInputText() {
    inputCreateFolder.setvalue("")
  }

  const renderMediasNotInFolder = (loaded: ImportedMedia[] | undefined) => {
    if (!loaded || !loaded.length) return null;

    return loaded
      .filter((video) => !video.archived)
      // temporarily comment out wait for merge code hash project_id before respond to client
      .filter((video) => video.project_id === projectId)
      .filter((video) => (!search.value ? true : new RegExp(search.value, 'gi').test(video.name || '')))
      .map((video, index) => (
        <DraggableMedia
          key={'media-not-in-folder-' + index}
          media={video}
          lastTranscibe={lastTranscibe}
          languages={languages}
          languageRegions={languageRegions}
          dictionarys={dictionarys}
          index={index}
          userHasWritePermission={userHasWritePermission}
          handleChangeSelected={handleChangeSelected}
          open={open}
          abortUploadFile={abortUploadFile}
          onSubUploadFile={onSubUploadFile}
          onSubTranslateFile={onSubTranslateFile}
          onStartTranscribe={onStartTranscribe}
          onResultTranscribe={onResultTranscribe}
          onStartTranslate={onStartTranslate}
          onTranscribeDone={onTranscribeDone}
          onChargeCreditBefore={onChargeCreditBefore}
          onOpenModalPayment={onOpenModalPayment}
          onCloseModalInsufficientCredit={onCloseModalInsufficientCredit}
          currentClickedMedia={currentClickedMedia}
          setCurrentClickedMedia={setCurrentClickedMedia}
        />
      ));
  }

  const renderMediaFolders = () => {
    if (!mediaFolders) return null;

    return mediaFolders
      .filter((folder) => !folder.archived)
      .filter((folder) => (!search.value ? true : new RegExp(search.value, 'gi').test(folder.name || '')))
      .map((folder, folderIdx) => (
        <DroppableFolder
          key={'folder-media-' + folderIdx}
          userHasWritePermission={userHasWritePermission}
          folder={folder}
          index={folderIdx}
          renderMediasNotInFolder={renderMediasNotInFolder}
          handleAmendMedia={handleAmendMedia}
          handleAmendMediaFolder={handleAmendMediaFolder}
          openModalConfirmDeleteFolder={openModalConfirmDeleteFolder}
        />
      ));
  };

  return (
    <div className="sidebar-body" style={{ display: loadedPanelActive ? 'inherit' : 'none' }}>
      <span style={{ fontStyle: 'italic', padding: '0 8px' }}>Choose file to load it in the player</span>
      <React.Fragment>
        <div className="input-search">
          <span className="prefix-icon">
            <FontAwesomeIcon icon={faSearch} width={16} height={16} />
          </span>
          <div style={{ width: '100%' }}>
            <input placeholder="Search list"  {...search} />
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="input-create-folder">
          <span onClick={createMeidaProject} className="prefix-icon">
            <FontAwesomeIcon icon={faPlus} width={16} height={16} />
          </span>
          <div style={{ width: "100%" }}>
            <input
              placeholder="Create new folder"
              onKeyDown={e => e.key === 'Enter' && createMeidaProject()}
              value={inputCreateFolder.value}
              onChange={inputCreateFolder.onChange}
            />
          </div>
        </div>
      </React.Fragment>
      <UploadVideo handleLoadFiles={handleLoadFiles} onAuthenticateGD={onAuthenticateGD} />
      <div className="sidebar-media">
        {!loaded && (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} /> Loading
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          <DroppableOutsideFolder
            handleAmendMedia={handleAmendMedia}
          >
            {renderMediaFolders()}
            {renderMediasNotInFolder(loaded)}
          </DroppableOutsideFolder>
        </DndProvider>
      </div>
    </div>
  );
}


function DraggableMedia({
  media,
  index,
  languages,
  languageRegions,
  lastTranscibe,
  dictionarys,
  userHasWritePermission,
  currentClickedMedia,
  setCurrentClickedMedia,
  handleChangeSelected,
  open,
  abortUploadFile,
  onSubUploadFile,
  onSubTranslateFile,
  onStartTranscribe,
  onResultTranscribe,
  onStartTranslate,
  onTranscribeDone,
  onOpenModalPayment,
  onChargeCreditBefore,
  onCloseModalInsufficientCredit,
}: any) {

  const [collectedProps, drag] = useDrag({
    type: ItemTypes.MEDIA,
    item: { media },
    canDrag() {
      return media.id;
    },
  })

  const [isOpenModalPayment, setOpenModalPayment] = useState(false);
  const [isOpenAddCredit, setOpenAddCredit] = useState(false);
  const [currentAmount, setCurrentAmount] = useState<ExtraEnum>();
  const [languagesData, setLanguagesData] = useState<string[]>([]);
  const [currentLanguageRegion, setLanguageRegion] = useState<LanguageRegion>()

  const isCancel = media.isCancel;
  const isClickedMedia = currentClickedMedia && (media.id === currentClickedMedia.id || media.isChargeFail || media.isCheckCharge);

  const isUploading = media.status === TranscribeStatus.UPLOADING;
  const isTranscribePaid = media.status === TranscribeStatus.TRANSCRIBE_PAID;
  const isTranslatePaid = media.status === TranscribeStatus.TRANSLATE_PAID;
  const isTranscribing = media.status === TranscribeStatus.TRANSCRIBING;
  const isTranscribed = media.status === TranscribeStatus.TRANSCRIBED;
  const isReady = media.status === TranscribeStatus.READY;
  const isTranslated = media.status === TranscribeStatus.TRANSLATED;
  const isTranslating = media.status === TranscribeStatus.TRANSLATING;
  const isFailed = media.status === TranscribeStatus.FAILED;
  const isRejected = media.status === TranscribeStatus.REJECTED;
  const isTranscoding = media.status === TranscribeStatus.TRANSCODING;

  useSubscription<PercentageProcessResponse, { mediaId: string }>(
    PROCESS_UPLOAD_FILE,
    {
      variables: { mediaId: media.id },
      // skip: media.status !== isTranscoding,
      onSubscriptionData: data => {
        const response = data.subscriptionData.data;
        if (
          response &&
          !response.processUploadFile.errorMessage &&
          response.processUploadFile.mediaId === media.id
        ) {
          onSubUploadFile(response.processUploadFile);
        }
      }
    }
  );

  useSubscription<{ processTranslateMultipleMedia: ProgressTranslateResponse }, { mediaId: string }>(
    SUB_TRANSLATE,
    {
      variables: { mediaId: media.id },
      onSubscriptionData: data => {
        // console.log("SUB_TRANSLATE", data.subscriptionData.data?.processTranslateMultipleMedia)
        const response = data.subscriptionData.data;
        if (response && response.processTranslateMultipleMedia.mediaIdTranslate === media.id) {
          onSubTranslateFile(response.processTranslateMultipleMedia);
        }
      }
    }
  );

  const shouldRetry = isRejected || isFailed;
  const shouldTranscribe = isTranscribed || isTranslatePaid || isTranslating;
  const isReadyToTranslate = isTranscribed || isTranslatePaid;
  const isReadyToTranscribe = isReady || isTranscribePaid;
  const shouldShowProgressBar = isUploading || isTranscribing || isTranslating || isTranscoding;
  const shouldShowButtonTranslate = !shouldShowProgressBar && (isTranscribed || isTranslatePaid);
  const shouldShowButtonTranscribe = !shouldShowProgressBar && (isReady || isTranscribePaid);

  return (
    <div
      ref={drag}
      className={`sidebar-media__item ${isClickedMedia ? "selected" : ""}`}
      title={media.name}
    >

      <Button color="link" className={`${isFailed ? "failed" : ""}`}
        onClick={() => {
          if (!isFailed) handleChangeSelected(media)
        }}
        title={media.name}
      >
        <div className="con-icon-file">
          {shouldTranscribe ? <IconTranscribe /> : shouldRetry ? <IconRetry /> : <IconFile />}
        </div>
        <div className="media-name">
          {media.name}
        </div>


        {shouldShowProgressBar && (
          <div className="con-progress-upload">
            <div style={{ fontSize: 10 }}>{`${Math.round(media.percentage)}% ${media.progressText}`}</div>
            <Progress className="progress-upload" value={Math.round(media.percentage)} />
          </div>
        )}


        {(shouldRetry || isTranslated) && (
          <div
            id={`uncontrolled-popover-${media.id}`}
            className={`${isFailed ? "txt-fail" : "txt-retry"}`}
            onClick={e => {
              e.stopPropagation();
              if (isRejected) {
                onStartTranscribe(media, undefined, media.dictionary_id);

                onChargeCreditBefore(
                  media,
                  [`${media.language_region_code}`],
                  'TRANSCRIBE'
                );
              }
            }}
          >
            {`${isRejected ? 'Retry Transcribing' :
              isFailed ? 'Upload failed' : ''}
            `}
          </div>
        )}

        <React.Fragment>
          {(shouldShowButtonTranscribe || shouldShowButtonTranslate) && (
            <React.Fragment>
              <Button
                id={`uncontrolled-popover-${media.id}`}
                className="btn-transcribe"
                onClick={e => {
                  e.stopPropagation()
                  setCurrentClickedMedia(media)
                }}
                onMouseDown={e => e.stopPropagation()}
              >
                {isReadyToTranslate ? 'Translate' : 'Transcribe'}
              </Button>

              <React.Fragment>
                {shouldShowButtonTranslate && isReadyToTranslate && !media.isChargeFail && (
                  <ModalTranslate
                    media={media}
                    languages={languages}
                    onClickOk={(media: ImportedMedia, listTranslate: Language[]) => {
                      setCurrentClickedMedia(null)
                      const listCode: string[] = [];
                      listTranslate.map(
                        language => language.translation_code && listCode.push(language.translation_code)
                      );

                      onChargeCreditBefore(media, listCode, 'TRANSLATE');
                    }}
                    onClose={() => setCurrentClickedMedia(null)}
                  />
                )}
              </React.Fragment>

              <React.Fragment>
                {!shouldShowProgressBar && isReadyToTranscribe && !media.isChargeFail && (
                  <ModalMediaLanguage
                    media={media}
                    languageRegions={languageRegions}
                    lastTranscibe={lastTranscibe}
                    dictionarys={dictionarys}
                    onClickOk={(languageRegion: LanguageRegion, media: ImportedMedia, dictionary?: DictionaryResponse | null) => {
                      onStartTranscribe(media, languageRegion, dictionary?.id);

                      onChargeCreditBefore(
                        media,
                        [`${languageRegion?.language?.code}-${languageRegion?.code}`],
                        'TRANSCRIBE'
                      );
                    }}
                    onClose={() => setCurrentClickedMedia(null)}
                  />
                )}
              </React.Fragment>
            </React.Fragment>
          )}
        </React.Fragment>

        <React.Fragment>
          {media.isChargeFail && (
            <ModalInsufficientCredit
              media={media}
              onClickOk={() => {
                onOpenModalPayment(media);
                onCloseModalInsufficientCredit(media);
              }}
              onClose={() => {
                onCloseModalInsufficientCredit(media);
                setCurrentClickedMedia(null);
              }} />
          )}
        </React.Fragment>
      </Button>
      <div style={{ width: 20 }}>
        {userHasWritePermission && (
          <Button
            onClick={e => {
              e.stopPropagation();
              if (shouldShowProgressBar) abortUploadFile(media)
              else if (!isCancel) open(media);
            }}
            className="btn-trash"
          >
            {!isTranscribing && !isTranslating && (
              <React.Fragment>
                {isCancel || media.isCheckCharge ? (
                  <Spinner size="sm" />
                ) : (
                  <FontAwesomeIcon
                    icon={
                      shouldShowProgressBar
                        ? faTimes
                        : faTrashAlt
                    }
                    width={16}
                    height={16}
                  />
                )}
              </React.Fragment>
            )}
          </Button>
        )}
      </div>
    </div>
  );
}

function DroppableFolder({
  folder,
  userHasWritePermission,
  handleAmendMediaFolder,
  renderMediasNotInFolder,
  handleAmendMedia,
  openModalConfirmDeleteFolder
}: any) {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.MEDIA],
    drop({ media }: any, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) return;
      if (media.project_folder_id === folder.id) return;

      handleAmendMedia(media.id, folder.id)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  })

  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputFolderName = useTextInput(folder.name);

  const toggle = () => setIsOpen(!isOpen);
  const toggleEdit = (e: any) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  }


  function onSaveFolderName() {
    if (
      handleAmendMediaFolder &&
      typeof handleAmendMediaFolder === "function" &&
      inputFolderName.value !== folder.name
    ) {
      handleAmendMediaFolder(folder.id, inputFolderName.value)
    }
    setIsEditing(!isEditing);
  }

  return (
    <div ref={drop} className={`sidebar-media__folder ${isOver ? "over" : ""} ${isOpen ? "active" : ""}`} title={folder.name}>
      <Button onClick={toggle} color="link" title={folder.name}>
        <span style={{ paddingRight: 4 }}>
          <FontAwesomeIcon icon={faFolder} width={18} height={18} />
        </span>
        <span className="folder-name">
          {
            !isEditing && folder.name
            ||
            <input
              autoFocus
              onClick={e => e.stopPropagation()}
              onBlur={onSaveFolderName}
              {...inputFolderName}
            />
          }
        </span>
        <span>
          {
            !isEditing && (
              !isOpen && <FontAwesomeIcon icon={faCaretRight} width={18} height={18} />
              || <FontAwesomeIcon icon={faCaretDown} width={18} height={18} />
            )
          }
        </span>
        <span className="sidebar-media__folder-tool">
          {
            userHasWritePermission &&
            <React.Fragment>
              <span onClick={toggleEdit}>
                <FontAwesomeIcon icon={faEdit} width={16} height={16} />
              </span>
              <span style={{ marginLeft: 4 }} onClick={(e) => {
                e.stopPropagation();
                openModalConfirmDeleteFolder(folder);
              }}>
                <FontAwesomeIcon icon={faTrashAlt} width={16} height={16} />
              </span>
            </React.Fragment>
          }
        </span>
      </Button>

      <Collapse isOpen={isOpen}>
        {renderMediasNotInFolder(folder.imported_media)}
      </Collapse>
    </div>
  )
}

function DroppableOutsideFolder({ handleAmendMedia, children }: any) {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.MEDIA],
    drop({ media }: any, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) return;
      if (media.project_folder_id === null) return;

      handleAmendMedia(media.id, null);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true })
    }),
  })

  return (
    <div ref={drop} className={`sidebar-media__outside-folder ${isOver ? "over" : ""}`}>
      {children}
    </div>
  )
}
