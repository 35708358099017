import { faPen, faInfoCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { updateDictionaryMutations } from '../../reactivities/operations/mutations/dictionary';

const DropdownCreateDict = (props: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ctn-dd-create-dict">
      <DropdownToggle className="button-dd-create-dict">
        <FontAwesomeIcon className="icon-lang" icon={faInfoCircle} width={24} height={16} color="rgb(200, 36, 127)" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="drop-down-create"
          onClick={async () => {
            await updateDictionaryMutations.updateIsOpenListDict(true);
            const element = document.getElementById('container-dictionary') as HTMLElement;
            element.click();
          }}
        >
          Create your custom dictionary{' '}
          <FontAwesomeIcon
            className="icon-lang"
            icon={faExternalLinkSquareAlt}
            width={24}
            height={16}
            color="rgb(200, 36, 127)"
          />{' '}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownCreateDict;
