import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { faSpinner, faCheckCircle, faTimes, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXPORT_TO_SOCIAL_MEDIA, GET_ASSEMBLY_LATEST_VERSION } from '../../queries';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { AlertColorEnum, alertStore } from '../../context/AlertContext';

type exportItem = {
  title: string;
  name: string;
  type: string;
  img: JSX.Element;
  options: [JSX.Element?];
  disable?: boolean;
  beta?: boolean;
  disabledOnProjectStatus?: boolean;
};

type ModalShareSocialMediaProps = {
  projectid: string;
};

const share_flatform_type = {
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
};

const exportSupported: Array<exportItem> = [
  {
    title: 'Facebook',
    name: 'facebook',
    type: share_flatform_type.FACEBOOK,
    img: <img src="/export-images/facebook.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Twitter',
    name: 'twitter',
    type: share_flatform_type.TWITTER,
    img: <img src="/export-images/twitter.png" />,
    disable: false,
    options: [],
  },
];

const ModalShareSocialMedia = (props: ModalShareSocialMediaProps) => {
  const { showAlert } = useContext(alertStore);
  const refFB = useRef(null);
  const refTwit = useRef(null);
  const { projectid } = props;
  const [isOpenShareSocialMedia, setIsOpenShareSocialMedia] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedItem, setSelectedItem] = useState<exportItem | null>(null);
  const [triggerShare, setTriggerShare] = useState({
    isShare: false,
    url: '',
  });

  const [getLatestAssembly, { data: segmentsData }] = useLazyQuery<AssemblyData, AssemblyProjectVars>(
    GET_ASSEMBLY_LATEST_VERSION,
    {
      fetchPolicy: 'cache-and-network',
      variables: { projectId: projectid },
    },
  );

  const [exportToSocialMedia] = useMutation<{
    projectId: string;
    share_flatform: string;
  }>(EXPORT_TO_SOCIAL_MEDIA, {
    onCompleted: (data: any) => {
      setTriggerShare({
        isShare: true,
        url: `${window.location.origin}/share-video/${data.exportToSocialMedia.id}`,
      });
      setIsProcessing(false);
      // setListNoti(prev => prev.filter(item => item.id !== data.deleteNotification.id));
    },
    onError: err => {
      console.log('EXPORT_TO_SOCIAL_MEDIA', err.message);
      showAlert({
        type: AlertColorEnum.danger,
        message: err.message,
      });
      setIsProcessing(false);
    },
  });

  useEffect(() => {
    if (isOpenShareSocialMedia) getLatestAssembly();
  }, [isOpenShareSocialMedia]);

  useEffect(() => {
    if (triggerShare.isShare) {
      let refShare;
      switch (selectedItem?.type) {
        case share_flatform_type.FACEBOOK:
          refShare = document.getElementById('fbShare');
          break;
        case share_flatform_type.TWITTER:
          refShare = document.getElementById('twitShare');
          break;
      }
      refShare?.click();
      setTriggerShare({
        isShare: false,
        url: '',
      });
    }
  }, [triggerShare.isShare, selectedItem]);

  const isSelectedExport = (item: exportItem) => {
    if (!selectedItem) return false;
    return item.name === (selectedItem?.name || '');
  };

  const toggle = () => setIsOpenShareSocialMedia(!isOpenShareSocialMedia);

  const handleShareClick = () => {
    if (selectedItem && selectedItem?.type in share_flatform_type) {
      setIsProcessing(true);
      exportToSocialMedia({ variables: { projectId: projectid, share_flatform: selectedItem?.type } });
    }
  };

  const isDisabledButtonPublish = selectedItem === null || !segmentsData?.getLatestAssembly?.segments?.length;
  console.log(segmentsData?.getLatestAssembly);

  return (
    <div id="modal-share-social-media">
      <FontAwesomeIcon onClick={toggle} icon={faShareAlt} className="share-social-icon" width={16} height={16} />
      <Modal id="modal-export" isOpen={isOpenShareSocialMedia} toggle={toggle}>
        <ModalBody>
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <span>Publish Project</span>
            <span
              onClick={toggle}
              style={{
                position: 'absolute',
                right: 0,
                color: '#828282',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faTimes} width={16} height={16} />
            </span>
          </div>
          <div className="export__container ctn_share_social_media">
            {exportSupported.map((item, index) => (
              <div
                className={`export__item ${isSelectedExport(item) ? 'active' : ''}`}
                key={'exportSupported-' + index}
                onClick={() => setSelectedItem(item)}
              >
                <span className="export__item-icon">
                  <FontAwesomeIcon icon={faCheckCircle} width={16} height={16} />
                </span>
                <div className="export-type">
                  <button
                    className="export__item-image"
                    // disabled={
                    //   activeProject?.status ===
                    //   item.disabledOnProjectStatus
                    // }
                    disabled={item.disable}
                  >
                    {item.img}
                  </button>
                </div>
                <div className="export__item-title">
                  {item.title}
                  {item.disable && (
                    <small>
                      <br />
                      Coming soon
                    </small>
                  )}
                  {item.beta && (
                    <small>
                      <br />
                      Beta
                    </small>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div style={{ textAlign: 'center' }}>
            <button
              className={`ss-btn ${isDisabledButtonPublish ? 'disabled' : ''}`}
              onClick={handleShareClick}
              disabled={isDisabledButtonPublish}
            >
              {isProcessing ? (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />
              ) : (
                <div>
                  <span>Publish</span>
                </div>
              )}

              {/* <span style={{ padding: '0 8px' }}>
                <FontAwesomeIcon icon={faDownload} width={16} height={16} />
              </span> */}
            </button>
            {isDisabledButtonPublish ? (
              <div className="export-social-warning">Please add at least 1 segment and choose sharing platform!</div>
            ) : null}

            <FacebookShareButton id="fbShare" ref={refFB} style={{ display: 'none' }} url={triggerShare.url}>
              Facebook
            </FacebookShareButton>
            <TwitterShareButton id="twitShare" ref={refTwit} style={{ display: 'none' }} url={triggerShare.url}>
              Twitter
            </TwitterShareButton>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalShareSocialMedia;
