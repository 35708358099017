import { gql, ReactiveVar } from '@apollo/client';
import { isOpenListDictVar } from '../../vars/dictionary';

function updateIsOpenListDict(isOpenListDictVar: ReactiveVar<boolean>) {
  return (isEditMode: boolean) => isOpenListDictVar(isEditMode);
}

export const updateDictionaryMutations = {
  updateIsOpenListDict: updateIsOpenListDict(isOpenListDictVar),
};

export const CREATE_DICTIONARY = gql`
  mutation createDictionary($languageId: ID!, $name: String, $tags: [String]) {
    createDictionary(languageId: $languageId, name: $name, tags: $tags) {
      id
      name
      tags
      language {
        id
      }
    }
  }
`;

export const UPDATE_DICTIONARY = gql`
  mutation updateDictionary($dictionaryId: ID!, $languageId: ID!, $name: String, $tags: [String]) {
    updateDictionary(dictionaryId: $dictionaryId, languageId: $languageId, name: $name, tags: $tags) {
      id
      name
      tags
      language {
        id
      }
    }
  }
`;

export const DELETE_DICTIONARY = gql`
  mutation deleteDictionary($dictionaryId: ID!) {
    deleteDictionary(dictionaryId: $dictionaryId)
  }
`;
